<template>
 <transition name="detailanimate" appear>
  <div class="detail-root" @click="closeDetailCard()">
    <div class="card-detail" ref="cardDetail" @click.stop>
      <div class="detail-card-view">
        <div class="content-close" @click="closeDetailCard()">
          <!-- close button -->
          <img
            src="@/assets/icons/detail_close_icon.svg"
            alt="closes"
            class="header-close"
            loading="lazy"
          />
        </div>
        <div class="card-detail-box">
          <div class="card-detail-box-contain">
            <div class="detail-container">
              <!-- image wrapper -->
              <div class="image-block">
                <div class="image-tags">
                  <div class="live-tag" v-if="showActiveTag">
                    {{ showActiveTag }}
                  </div>
                  <div class="view-count" v-if="viewCount && viewCount !== 0">
                    <img :src="iconEyeOpenPath" alt="eye img" loading="lazy" />
                    <p>{{ viewCount }} views</p>
                  </div>
                </div>

                <img
                  class="background-img-div"
                  :src="backgroundImage ? backgroundImage : defaultImage"
                  @error="
                    ($event) => {
                      $event.target.src = defaultImage;
                    }
                  "
                  alt="image"
                />
                <!-- <div class="premium-highlight" v-if="isPremium"><img src="@/assets/icons/premium_star.svg" alt="premium"></div> -->
                <div class="premium-tag" v-if="isPremium"> <img :src="premiumIcon" alt="premium"> <span>premium</span></div>
                <div class="background-gradient"></div>
              </div>
              <!-- image wrapper close-->
              <!-- content related buttons -->
              <div class="content-buttons-box">
                <div
                  class="vlt-btn buy-btn"
                  v-if="
                    cardContent && cardContent.premieringstatus !== 'ANNOUNCED' &&
                    buttonDisplayDiv
                  "
                >
                  <button
                    v-if="buttonName"
                    class="btn-login"
                    ref="buyBtn"
                    @click="routeCard(cardContent)"
                  >
                    {{ buttonName }}
                  </button>
                  <app-loader v-show="!buttonName"></app-loader>
                </div>

                <div class="detail-card-ioncs">
                  <div class="content-buttons button-hover">
                    <button
                      type="button"
                      class="watchlist-btn"
                      style="cursor: pointer"
                      @click="actFunctionality('like')"
                    >
                      <LikeBtn :isClicked="isLiked" />
                    </button>
                    <p class="count-like-dislike">{{ likecount }}</p>
                  </div>
                  <div class="content-buttons button-hover">
                    <button
                      type="button"
                      class="watchlist-btn"
                      style="cursor: pointer"
                      @click="actFunctionality('dislike')"
                    >
                      <DislikeBtn :isClicked="isDisliked" />
                    </button>
                    <p class="count-like-dislike">{{ dislikeCount }}</p>
                  </div>
                  <div class="content-buttons button-hover">
                    <button
                      type="button"
                      class="watchlist-btn"
                      v-if="!firebaseStatus"
                    >
                      <img
                        src="@/assets/icons/watchList.svg"
                        alt="watchlist"
                        style="cursor: pointer"
                        @click="actFunctionality('watch')"
                        loading="lazy"
                      />
                    </button>
                    <button
                      type="button"
                      class="watchlist-btn"
                      style="cursor: pointer"
                      @click="actFunctionality('unwatch')"
                      v-if="firebaseStatus"
                    >
                      <WatchlistSvg />
                    </button>
                  </div>
                  <div
                    class="content-buttons share-block button-hover"
                    v-if="shareConfigured"
                  >
                    <button type="button" class="watchlist-btn">
                      <img
                        src="@/assets/icons/share_icon.svg"
                        alt="share"
                        @click="actShare"
                        style="cursor: pointer"
                        loading="lazy"
                      />
                    </button>
                  </div>

                  <div
                    class="button-hover share-buttons transitionClass"
                    v-if="isShare"
                  >
                    <Share
                      class="card-share-btn"
                      :content="cardContent"
                      :showPopup="isShare"
                      :displayButton="isDisplayWatch"
                      :buttonDisplayDiv="buttonDisplayDiv"
                    />
                  </div>
                </div>
              </div>
              <!-- content related buttons close-->

              <!-- card tab buttons -->
              <div class="related-box">
                <div class="card-tab" v-if="displayTabs">
                  <button
                    class="tablink"
                    v-for="(tab, i) in cardTabs"
                    :key="i"
                    :class="tab === activeTabName ? 'activetabname' : ''"
                    @click="renderTab(tab)"
                  >
                    <span>{{ tab }}</span>
                  </button>
                </div>

                <!-- card tab buttons close-->
                <!-- overview/relation/episodes section-->
                <div class="section-box">
                  <div
                    class="card-tab-content"
                    v-show="activeTabName === 'Overview'"
                  >
                    <div
                      :id="cardContent?cardContent.title + 'Overview':'overview'"
                      class="tabcontent"
                      style="background-size: 100% 100%"
                      :class="'Overview' === activeTabName ? 'activetab' : ''"
                    >
                      <div class="overview-class">
                        <div class="overview-left">
                          <div class="overview-left-content">
                            <ContentDescription
                              :updateLiveTag="updateLiveTag"
                              :content="cardContent"
                              v-if="cardContent"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="overview-right" v-show="displayTabs">
                    <div
                      class="slider-main"
                      v-if="activeTabName !== 'Overview'"
                    >
                      <ContentSlider
                        :activeTabName="activeTabName"
                        :isDisplayWatch="isDisplayWatch"
                        :cardContent="cardContent"
                        :routeCard="routeCard"
                        :closeDetailCard="closeDetailCard"
                      />
                    </div>
                  </div>
                </div>
                <!-- overview/relation/episodes section-->
              </div>
              <!-- related box close-->
            </div>
            <!-- detail container close -->
          </div>
        </div>
        <!--</div>     
    </div>-->
      </div>
    </div>
  </div>
 </transition>
</template>

<script>
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import purchaseMixins from "@/mixins/purchaseMixins";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";
import "nm/node-snackbar/dist/snackbar.css";
import snackbar from "node-snackbar";

export default {
  name: "ContentDetailCard",
  props: {
    propContent: {
      type: Object,
    },
  },
  data() {
    return {
      cardHeight: 500,
      cardTabs: ["Overview"],
      activeTabName: "Overview",
      showPurchaseDetail: false,
      purchaseDetails: "",
      type: undefined,
      languages: [],
      showPurchaseButton: true,
      contentPrice: null,
      progress: null,
      firebaseStatus: false,
      likeStatus: false,
      dislikeStatus: false,
      watchStatus: false,
      isShare: false,
      displayTabs: true,
      showActiveTag: false,
      addUpdateFireBase: false,
      backData: "",
      paymentInitData: null,
      backgroundImage: null,
      defaultImage: require("@/assets/icons/placeholder-landscape.png"),
      preBookStatus: false,
      displayRelated: false,
      isDisplayWatch: false,
      eventDisplayWatch: false,
      isGuest: false,
      buttonName: "",
      watchable:false,
      likecount: 0,
      dislikeCount: 0,
      likeCountDisplay: null,
      likedislike: null,
      updatelike: null,
      dislikeCountDisplay: null,
      buttonDisplayDiv: true,
      flagAutologin: true,
      appVendorId: "",
      statedata: null,
      cardContent: this.propContent,
      viewCount: null,
      iconEyeOpenPath: require("@/assets/icons/detail_eye_open.svg"),
      premiumIcon: require( '@/assets/icons/star.svg'),
      episodeContent: null,
      episodePromise: null,
      contentOnFirebase:false
    };
  },
  computed: {
    ...mapGetters([
      "appScreens",
      "subscriberId",
      "appConfig",
      "appUser",
      "ticketResponse",
      "detailCardStatus",
      "contentData",
      "contentSubscriber",
      "appRouteHistory"
    ]),
    isLiked() {
      return this.likedislike === "LIKE";
    },
    isDisliked() {
      return this.likedislike === "DISLIKE";
    },
    sliderName() {
      if (this.cardContent && this.cardContent.title) {
        return this.cardContent.title.toLowerCase().replace(/ /g, "-");
      }
      else return '';
    },
    isContentFree() {
      return (
        this.cardContent.price &&
        Array.isArray(this.cardContent.price) &&
        this.cardContent.price.some((element) => parseInt(element.amount) === 0)
      );
    },
    isPreBook() {
      return (
        this.cardContent && this.cardContent.premieringstatus === "PREBOOK"
      );
    },
    isAnnounced() {
      return (
        this.cardContent && this.cardContent.premieringstatus === "ANNOUNCED"
      );
    },
    isPremium(){
      return (this.cardContent?.pricemodel==='PREMIUM') && (
        Array.isArray( this.ticketResponse )? 
        this.ticketResponse.every( ticket => ticket.contentid !== this.cardContent.contentid ) : true  
      );
    },
    purchase(){
      return ( this.appConfig?.featureEnabled?.purchase && 
                     this.p_priceAvailable( this.cardContent )
                    );
    },
    subscription(){
      return ( this.appConfig?.featureEnabled?.subscription
              && ( this.cardContent?.pricemodel !== 'PREMIUM' )  
            );
    },
    isExpired() {
      if (
        this.cardContent &&
        this.cardContent.contenttype === "EVENT" &&
        this.cardContent.todate
      ) {
        return this.checkEventEnded(this.cardContent.todate);
      } else {
        return false;
      }
    },
    shareConfigured(){
            return (this.appConfig.featureEnabled &&
              this.appConfig.featureEnabled.share &&
              this.appConfig.featureEnabled.share === true);
    }
  },
  watch: {
    propContent(val) {
      this.cardContent = val;
    },
    $route(to,from){
      if(to.name===from.name){
        this.fetchContent();
      }
    },
    cardContent(val, pastcontent) {
      if (
        val &&
        this.episodeContent &&
        val.seriesid !== this.episodeContent.seriesid
      ) {
        this.episodeContent = null;
      }
      this.pushToFirebase(this.statedata, pastcontent).catch(()=>{});
      this.cardContent = val;
      this.statedata = null;
      this.activeTabName = "Overview";
      this.watchStatus = false;
      this.likeStatus = false;
      this.dislikeStatus = false;
      this.likedislike = null;
      this.firebaseStatus = false;
      this.isShare = false;
      this.purchaseDetails = "";
      this.addUpdateFireBase = false;
      this.actFetchFirebase();
      this.setPoster();
      this.actDisplayRelated();
      this.resetCardtabs();
      this.buttonName = "";
      this.watchable=false;
      this.setPurchaseStatus();
    },
    displayRelated(val) {
      this.resetCardtabs();
    },
    subscriberId(val) {
      if (val) {
        this.setPurchaseStatus();
        this.commitContentSubscriber(val);
        this.actFetchFirebase();
      }
    },
  },
  methods: {
    ...mapActions(["actGetCategory", "actGetPlans",'actListContents']),
    ...mapMutations([
      "commitDetailCardStatus",
      "commitCurrentContent",
      "commitContentData",
      "commitContentSubscriber",
    ]),
    updateLiveTag(val) {
      this.showActiveTag = val;
    },
    actShare() {
      this.isShare = !this.isShare;
    },
    updateWatchlistFire() {
      this.commitContentData({
        contentid: this.cardContent.contentid,
        data: {
          likecount: this.likecount,
          dislikeCount: this.dislikeCount,
          likedislike: this.likedislike,
          inwatchlist: this.firebaseStatus,
        },
      });
      this.pushToFirebase(this.statedata, this.cardContent).then(() => {
        this.statedata = {
          likedislike: this.likedislike,
          inwatchlist: this.firebaseStatus,
        };
        EventBus.$emit("firebase_content_status", {
          contentid: this.cardContent.contentid,
          status: this.firebaseStatus,
        });
      }).catch(()=>{});
    },
    actFunctionality(val) {
      if (!this.subscriberId) {
        let data = {};
        data.val = val;
        data.content = this.cardContent;
        this.commitDetailCardStatus(data);
        this.closeDetailCard();
        return EventBus.$emit("LoginPopup");
      }
      let pastlikedislike = this.likedislike;
      if (val === "like") {
        this.likedislike = this.likedislike === "LIKE" ? "NONE" : "LIKE";
        this.updatelikedislike(this.likedislike, pastlikedislike);
      } else if (val === "dislike") {
        this.likedislike = this.likedislike === "DISLIKE" ? "NONE" : "DISLIKE";
        this.updatelikedislike(this.likedislike, pastlikedislike);
      } else if (val === "watch") {
        this.firebaseStatus = true;
        snackbar.show({ text: "Added to watchlist", pos: "bottom-center" });
        this.updateWatchlistFire();
      } else if (val === "unwatch") {
        this.firebaseStatus = false;
        snackbar.show({ text: "Removed from watchlist", pos: "bottom-center" });
        this.updateWatchlistFire();
      }
    },
    updatelikedislike(newval, oldval) {
      if (oldval === "LIKE" && this.likecount) {
        this.likecount--;
      }
      if (oldval === "DISLIKE" && this.dislikeCount) {
        this.dislikeCount--;
      }
      if (newval === "LIKE") {
        this.likecount++;
        snackbar.show({ text: "Liked", pos: "bottom-center" });
      }
      if (newval === "DISLIKE") {
        this.dislikeCount++;
        snackbar.show({ text: "Disliked", pos: "bottom-center" });
      }
      if (newval === "NONE") {
        snackbar.show({
          text: "Neither liked or disliked",
          pos: "bottom-center",
        });
      }
      this.commitContentData({
        contentid: this.cardContent.contentid,
        data: {
          likecount: this.likecount,
          dislikeCount: this.dislikeCount,
          likedislike: this.likedislike,
          inwatchlist: this.firebaseStatus,
        },
      });
    },
    closeDetailCard() {
      this.appRouteHistory.length>1?this.$router.go(-1):this.$router.replace('/');
    },
    resetCardtabs() {
      this.cardTabs = ["Overview"];
      if (this.cardContent.seriesid && this.cardContent.episodecount > 0) {
        this.cardTabs.splice(1, 0, "Episodes");
      }

      if (this.cardContent.trailer && this.cardContent.trailer.length)
        this.cardTabs.push("Trailers");
      if (this.displayRelated) {
        this.cardTabs.push("Related");
      }
    },
    fetchCardHeight() {
      if (this.isMobile()) {
        if (window.innerWidth < 1990) {
          this.cardHeight = 550;
          if (this.cardContent.description.length > 180) {
            this.cardHeight += 200;
          }
        }
      } else {
        let bodyWidth = document.body.offsetWidth - 100;
        let cardHeight = Math.round(bodyWidth / (16 / 9));
        if (cardHeight) {
          this.cardHeight = cardHeight + 60;
        }
      }
    },
    renderTab(tab) {
      this.activeTabName = tab;
    },
    routeCard(content = this.cardContent) {
     
      if ( !this.watchable) {
        let data = {};
        if (this.purchase && this.subscription) {
          data = {
            intermediate: true,
            content: content,
          };
        } else if (this.subscription) {
          data = {
            subscription: true,
            menuClicked: false,
          };
          this.backData = "subscription";
        } else if (this.purchase) {
          data = {
            purchase: true,
            content: content,
          };
          this.backData = "purchase";
        } else {
          data = {
            payment: true,
            content: content,
            paymentdata: this.paymentInitData,
          };
        }

        //check if user loggedin:
        if( !this.subscriberId ){
            content.context__ = data ;
            this.commitCurrentContent(content);
            EventBus.$emit("LoginPopup");
        }
        else EventBus.$emit("paymentInitPopup", data);
        
        this.closeDetailCard();
      } else if (this.subscriberId && this.watchable) {
        this.playContent(this.episodeContent ? this.episodeContent : content);
      }
    },
    async playContent(content) {
      try{
        if(content.seriesid && !content.episodenum){
          let episodes=await this.getEpisodesList(content);
          if(Array.isArray(episodes))content=episodes[0];
        }
        EventBus.$emit("loadPlayer", content);
      }
      catch(err){
        console.log(err);
      }
    },
    getEpisodesList(content){
            let params = {};
            params.seriesid = content.seriesid;
            console.log(params);
            return this.actListContents(params)
              .then((response) => {
                if (response.error)throw new Error('no contents');

                return response;
              })
    },
    addWatchListCB() {
      if (this.subscriberId) {
        this.addToFireWatchlist(this.cardContent);
      }
    },
    removeWatchlistCB() {
      // this.commitWatchList("remove");
      this.removeFromFireWatchlist(this.cardContent);
      // this.addColor = false;
    },
    actFetchFirebase() {
      let storedata = this.contentData(this.cardContent.contentid);
      if (storedata) {
        this.likecount = storedata.likecount;
        this.dislikeCount = storedata.dislikeCount;
        this.likedislike = storedata.likedislike;
        this.firebaseStatus = storedata.inwatchlist;
        this.contentOnFirebase=storedata.onFirebase;
        this.statedata = {
          likedislike: this.likedislike,
          inwatchlist: this.firebaseStatus,
        };
        return;
      }

      this.fetchContentInfoFromFireBase(this.cardContent)
        .then((result) => {
          if (result) {
            this.firebaseStatus = result.inwatchlist;

            this.likedislike = result.likedislike;

            this.contentOnFirebase=true;
          } else {
            this.firebaseStatus = false;
            this.likedislike = "NONE";
            this.contentOnFirebase=false;
          }

          this.statedata = {
            likedislike: this.likedislike,
            inwatchlist: this.firebaseStatus,
          };

          return Promise.all([
            this.actFetchLikeCount(),
            this.actFetchDislikeCount(),
          ]);
        })
        .then(() => {
          this.commitContentData({
            contentid: this.cardContent.contentid,
            data: {
              likecount: this.likecount,
              dislikeCount: this.dislikeCount,
              likedislike: this.likedislike,
              inwatchlist: this.firebaseStatus,
              onFirebase:this.contentOnFirebase
            },
          });

          this.preLoginUpdate();
        });
    },
    preLoginUpdate() {
      if (
        this.detailCardStatus &&
        this.subscriberId &&
        (this.detailCardStatus.content.contentid === this.cardContent.contentid)
      ) {
        this.actFunctionality(this.detailCardStatus.val);
        this.commitDetailCardStatus("");
      }
    },
    pushToFirebase(oldstate, content) {
      if (!oldstate || !(oldstate instanceof Object)) return Promise.reject('no state.');
      if (
        oldstate.inwatchlist === this.firebaseStatus &&
        oldstate.likedislike === this.likedislike
      ) {
        return Promise.reject("data is same");
      }

      let data = Object.assign({}, content);

      let prevState = oldstate.likedislike;
      data.likeDislikeDisplay =
        this.likedislike === prevState ? null : this.likedislike;
      data.inwatchlist = this.firebaseStatus;

      
        if (this.contentOnFirebase) {
          return this.updateIntoFirebase(data, {}, prevState);
        } else {
          return this.addToFirebase(data).then(()=>{
            this.commitContentData({
                  contentid: this.cardContent.contentid,
                  data: {
                    onFirebase:true
                  }
              });
          });
        }
      
    },
    setPoster() {
      this.backgroundImage = null;
      let content = this.episodeContent
        ? this.episodeContent
        : this.cardContent;

      if (content && content.posters) {
        const quality = document.body.clientWidth > 900 ? 'HD': 'SD' ; 
        this.backgroundImage = content.posters
                            .filter( poster => poster.orientation === 'LANDSCAPE')
                            .reduce( ( img, poster ) => {
                              if( !img ) return poster.url;
                              return  poster.quality === quality ? poster.url : img ; 
                            } , null)

      } else if (content && content.poster) {
        this.backgroundImage = content.poster;
      } else {
        this.backgroundImage = null;
      }
    },
    actDisplayRelated() {
      let param = {};
      param.contenttype = this.cardContent.contenttype;
      param.category = this.cardContent.category;
      this.actGetCategory(param)
        .then((response) => {
          this.showLoading = false;
          if (!response.error) {
            let value = parseInt(response[0].count);
            if (value > 1) {
              this.displayRelated = true;
            } else {
              this.displayRelated = false;
            }
          } else {
            this.displayRelated = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.displayRelated = false;
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.actDisplayRelated);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    notExpired(content, key) {
      // let currentDate = new Date();

      // console.log("content ", content[key])
      // let currentDate = this.getTodayDate();

      // console.log("current date ", currentDate)
      // let expiryDate = new Date(content[key]);
      let expiryDate = this.checkForContentExpiryDate(content[key]);

      console.log("expiry date ", expiryDate);
      return expiryDate;
    },
    isPurchased() {
      let purchasedData =
        this.ticketResponse &&
        this.ticketResponse.find(
          (element) => element.contentid === this.cardContent.contentid
        );

      if (
        purchasedData &&
        purchasedData.status === "ACTIVE" &&
        !this.notExpired(purchasedData, "expiry")
      )
        return true;
      else return false;
    },
    setPurchaseStatus() {
      let purchaseData = this.actPurchaseStatus(this.cardContent);
      this.purchaseDetails = purchaseData.purchaseDetails;
      this.buttonDisplayDiv = purchaseData.buttonDisplayDiv;
      this.isDisplayWatch = purchaseData.isDisplayWatch;
      this.buttonName = purchaseData.buttonName;
      this.watchable=purchaseData.watchable;
    },

    actFetchLikeCount() {
      let param = {};
      param.contentid = this.cardContent.contentid;
      param.type = "like";
      return this.fetchCountFirebase(param).then((result) => {
        if (result && !isNaN(Number(result))) {
          this.likecount = Number(result);
        } else {
          this.likecount = 0;
        }
      });
    },
    unloadData() {
      this.pushToFirebase(this.statedata, this.cardContent).catch(()=>{});
    },
    actFetchDislikeCount() {
      let param = {};
      param.contentid = this.cardContent.contentid;
      param.type = "dislike";
      return this.fetchCountFirebase(param).then((result) => {
        if (result && !isNaN(Number(result))) {
          this.dislikeCount = Number(result);
        } else {
          this.dislikeCount = 0;
        }
      });
    },
    contentDetailAnalytics() {
      let GA = {
        ContentTitle: this.cardContent.title,
        ContentID: this.cardContent.contentid,
        Category: this.cardContent.category,
        ContentType: this.cardContent.contenttype,
      };
      console.log("GA ", GA);
      this.ContentDetails(GA);
    },
    getViewCount() {
      let obj = {};
      obj.contentid = this.cardContent.contentid;
      obj.type = "views";
      obj.unique=this.appConfig.featureEnabled &&
              this.appConfig.featureEnabled.uniqueViewCount;

      this.fetchCountFirebase(obj)
        .then((response) => {
          if (!response.error) {
            this.viewCount = this.viewCountShort(response);
          }
        })
        .catch((error) => {
          console.log("error ", error);
          obj.unique=false; //fetch normal view if unique views not found.
          return this.fetchCountFirebase(obj)
        })
        .then((response)=>{
           if(!response)return;
           if (!response.error) {
               this.viewCount = this.viewCountShort(response);
            }
        })
        .catch((error) => {
          console.log("error ", error);
          obj.unique=false;
        })

    },
    switchEpisodeMode() {
      if (this.cardContent.seriesid && this.cardContent.episodenum) {
        if (!this.episodePromise) {
          this.episodePromise = this.actGetContent(this.cardContent.seriesid)
            .then((response) => {
              if (response.error) {
                return null;
              }
              this.episodeContent = this.cardContent;
              this.cardContent = response;
            })
            .catch((err) => {
              console.log(err);
            });
        }
        return this.episodePromise;
      } else
        return {
          then(callback) {
            callback();
          },
        };
    },

    fetchContent(){
        new Promise((resolve) => {
            let contentid=this.$route.params.contentid;

            this.actGetContent(contentid)
                .then((response) => {
                      if (response.error) {
                        throw new Error(response.error);
                      }
                      this.cardContent=response;
                      resolve();
                })
                .catch((err) => {
                  console.log(err);
                });
        })
        .then(this.switchEpisodeMode)
        .then(() => {
              this.setPurchaseStatus();  
              this.appVendorId = JSON.parse(localStorage.getItem("appVendor")).vendorId;
              this.type = this.cardContent.contenttype;
              this.resetCardtabs();
              if (this.subscriberId !== this.contentSubscriber) {
                this.commitContentSubscriber(this.subscriberId);
              }

            this.actFetchFirebase();

            this.contentDetailAnalytics();
            this.getViewCount();
            this.actDisplayRelated();
            this.setPoster();
            if (
              this.cardContent.contenttype === "EVENT" &&
              this.cardContent.premieringstatus === "PREBOOK"
            ) {
              this.preBookStatus = false;
            } else {
              this.preBookStatus = true;
            }

    
        });
    }



  },
  created() {
      this.fetchContent();
      EventBus.$on("checkWatchButton", (data) => {
        if (data) {
          this.eventDisplayWatch = true;
          this.buttonName = "";
          this.watchable=false;
          this.setPurchaseStatus();
        }
      });
      EventBus.$on("buyContent", (data) => {
        if (data) {
          this.routeCard(data.content);
        }
      });

      
      EventBus.$on("backPopup", (data) => {
        if (!data) {
          //backpop may not need this listener. Need to be tested.
          if (this.subscription || this.purchase) {
            this.purchaseDetails = "";
          } 
          this.routeCard(this.cardContent);
        }
      });
      EventBus.$on("planPurchasedSuccess", this.setPurchaseStatus);
      EventBus.$on("purchaseDetailsUpdated", this.setPurchaseStatus);
      window.onbeforeunload = this.unloadData;
 
  },
  components: {
    ContentDescription: () =>
      import(
        /* webpackChunkName: "ContentDescription" */ "@/components/Shared/templates/contentDescription.vue"
      ),
    ContentSlider: () =>
      import(
        /* webpackChunkName: "contentslider" */ "@/components/Shared/templates/contentDetailCardSlider.vue"
      ),
    LikeBtn: () =>
      import(
        /* webpackChunkName: "likebutton" */ "@/components/svgComponents/likeBtn.vue"
      ),
    DislikeBtn: () =>
      import(
        /* webpackChunkName: "dislike button" */ "@/components/svgComponents/dislikeBtn.vue"
      ),
    WatchlistSvg: () => import("@/components/svgComponents/watchlist_tick.vue"),
    Share: () =>
      import(/* webpackChunkName: "share" */ "@/components/Popups/share.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins, purchaseMixins, FirebaseActions, GoogleAnalytics],
  beforeDestroy() {
    this.pushToFirebase(this.statedata, this.cardContent).catch(()=>{});
    EventBus.$off("planPurchasedSuccess", this.setPurchaseStatus);
    EventBus.$off("purchaseDetailsUpdated", this.setPurchaseStatus);
    EventBus.$emit('detailCardClosed');
  },
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "~sass/modules/_hovereffects.scss";

.detail-root {
  position: fixed;
  background-color:rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow-x:hidden;
  overflow-y:auto;
  transition:background-color 0.3s;
  &.detailanimate-leave-active,
  &.detailanimate-enter-active{
    overflow-y:hidden;
    background:transparent;
  }
  &.detailanimate-leave-active{
    transition:none;
  }
}

.card-detail {
  position: absolute;
  top: 4vh;
  left: 50%;
  margin-bottom: 2vh;
  transform: translateX(-50%);
  width: 60vw;
  z-index: 100;
  --icon-width: clamp(14px, 3vw, 43px);
}

.detail-card-view {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 8px, rgba(0, 0, 0, 0.7) 0px 0px 12px;
  margin-bottom: 4vh;
}

.card-detail-box {
  width: 100%;
  height: auto;
  position: relative;
  background-color: $clr-bg-gray-dark;
  padding: 2vw 6vw;
}

// detail card image..
.image-block {
  position: relative;
  width: 100%;
  height: 0;
  padding: 28.125% 0;
  border-radius: 5px;
  overflow: hidden;

  .background-img-div {
    position: absolute;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .premium-highlight{
    position:absolute;
    top:5px;
    left:5px;
    width:20px;
    img[alt='premium']{
      display: block;
      width:100%;
    }
  }
  .premium-tag{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    text-transform: uppercase;
    position:absolute;
    top: 5px;
    left: 5px;
    background: $tag-primary;
    border-radius: 10px;
    padding: 3px 10px;
    font-size: 1rem;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    color: $font-clr-white;
      img{
        display: block;
        height: 1rem;
      }
      span{
        display: inline-block;
        vertical-align: bottom;
        font-size: 1rem;
        height: 1.5rem;
        line-height: 1.6rem;
      }
  }
  .background-gradient {
    top: 0;
    left: 0;
    background: transparent
      linear-gradient(
        0deg,
        $clr-color-black-4 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0) 100%
      )
      0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .image-tags {
    position: absolute;
    left: 2%;
    bottom: 2%;
    z-index: 3;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .live-tag {
    height: max-content;
    text-align: center;
    background: $clr-color-red 0% 0% no-repeat padding-box;
    border-radius: 2px;
    padding: 3px 10px;
    font-size: 0.625rem;
    font-family: $font-family;
    font-weight: $font-weight-bold;
    color: $font-clr-white;
    margin-right: 5px;
  }
  .view-count {
    height: max-content;
    text-align: center;
    font-size: 0.688rem;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    color: $font-clr-white;
    display: flex;
    padding: 2px 3px;
    border-radius: 2px;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
}

//@content related buttons.

.content-buttons-box {
  height: max-content;
  margin: 2vw 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .watchlist-btn {
    background: transparent;
    border: none;
  }
  .detail-card-ioncs {
    display: flex;
    flex-flow: row nowrap;
    min-width: 20vw;
    width: max-content;
    position: relative;
    .content-buttons {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-right: 3vw;
      &.share-block {
        margin-right: 0;
      }

      .count-like-dislike {
        color: $clr-bg-gray-light-7;
        position: absolute;
        font-family: $font-family;
        font-weight: $font-weight-regular;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
      img,
      button {
        display: block;
        width: 100%;
      }
      button {
        width: 3vw;
        width: var(--icon-width);
        height: calc(3vw + 1px);
        height: calc(var(--icon-width) + 1px);
      }
    }
    .card-share-btn {
      margin-right: 15px;
      height: 100%;
    }
    .button-hover:hover {
      transform: translateY(-2px);
      transition: 0.2s;
    }

    .share-buttons {
      position: absolute;
      left: 100%;
      height: 100%;
    }
  }
  .vlt-btn {
    border: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $font-clr-white;
    background: none;
    border-radius: 16px;
    opacity: 1;
  }
  .buy-btn {
    width: 30%;
    border-radius: 10px;
    margin-right: 4%;
    position: relative;
    cursor: pointer;
    button {
      padding: 9px 5px;
    }
  }
}

//route tab cards styling
.card-tab {
  width: 50%;
  max-width: 80%;
  height: max-content;
  margin: 3vw 0 2vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  .tablink {
    background: transparent;
    border: none;
    outline: none;
    padding-right: max(10px, 20px);
    cursor: pointer;
    text-transform: capitalize;
    color: $font-clr-white-1;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.37px;
    line-height: 25px;
    &:hover {
      color: $font-clr-white;
    }
  }
}

.related-box {
  position: relative;
}

// section tab styling

.section-box {
  min-height: 200px;
  .overview-right {
    width: 100%;
  }
}

///OLD STYLESSSS>>>>

.card-detail-box-contain {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.transitionClass {
  -webkit-animation: slide 1s backwards;
  -webkit-animation-delay: 0s;
  animation: slide 1s backwards;
  animation-delay: 0s;
}
@-webkit-keyframes slide {
  0% {
    right: -50px;
  }
  100% {
    right: 0;
  }
}

@keyframes slide {
  0% {
    right: -50px;
  }
  100% {
    right: 0;
  }
}

.content-close {
  cursor: pointer;
  @include closeIconHover;
  top: 2vw;
  right: 2vw;
  height: 2vw;
  width: 2vw;
  z-index: 1;
  position: absolute;
  background: black;
  border-radius: 50%;
  .header-close {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
}
.detail-container {
  width: 100%;
  height: 100%;
}
.card-tab-content {
  height: 100%;
}
/* Style the tab content */
.tabcontent {
  //display: none;
  height: 100%;
}

.activetabname {
  span {
    padding-bottom: 5px;
    border-bottom: 2px solid $button-primary;
    color: $font-clr-white;
  }
}
span {
  font-size: 1.2rem;
}
.activetab {
  display: block !important;
}
.inactivetab {
  display: none;
}
.tabstyle {
  background-color: $clr-dark-hlt-3;
}
.overview-class {
  display: flex;
  height: 100%;
  width: 100%;
  .overview-left {
    width: 100%;
  }
}

.seasons {
  display: flex;
  background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 0.5;
  padding: 1% 2%;
  // width: 50%;
  width: 70%;
  // margin-top: 13%;
  label {
    width: 20%;
    margin-right: 5%;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    letter-spacing: 0px;
    color: $font-clr-white;
  }
}
.season-list {
  display: flex;
  width: 80%;
  overflow-y: hidden;
  overflow-x: auto;
  span {
    display: inline-block;
    margin-left: 10%;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    letter-spacing: 0px;
    color: $font-clr-gray-1;
  }
}

//max576
@include breakpoint(max576) {
  .btn-login {
    border-radius: 5px !important;
  }
  .card-detail {
    --icon-width: clamp(14px, 7vw, 43px);
  }
  .content-close {
    top: 3vw;
    right: 3vw;
    height: 4vw;
    width: 4vw;
  }

  .card-detail-box {
    padding: 2vw;
  }
  .content-buttons-box .detail-card-ioncs {
    width: 50%;

    .share-buttons {
      left: 100%;
      top: 100%;
      transform: translateX(-210px);
    }
  }

  .related-box {
    position: relative;
    margin-top: 5vw;
  }

  .card-tab {
    width: 100%;
    max-width: 100%;
  }
}

//max768
@include breakpoint(max768) {
  .card-detail {
    width: 90vw;
  }
}

//min768max980
@include breakpoint(min768max980) {
  .card-detail {
    width: 80vw;
  }
}

//min980max1200
@include breakpoint(min980max1200) {
  .card-detail {
    width: 70vw;
  }
}

/* Extra small devices (phones, 600px and down) */
@include breakpoint(max600) {
  .buy-btn .btn-login {
    font-size: 1rem !important;
  }
}
</style>